<template>
  <div class="auth-price-desc">
    Цена
    <app-price
      :price="price"
      is-symbol-rub
    />
    для участников программы лояльности. <br />
    <button
      type="button"
      class="product-auth-price__link"
      @click="triggerAuthModal"
    >
      Войдите
    </button> на сайт.
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';

import useAuthorization from '@/compositions/authorization/useAuthorization';

import AppPrice from '@/components/AppPrice';

export default defineComponent({
  name: 'AuthDiscountDesc',
  components: {
    AppPrice,
  },
  props: {
    price: { type: [Number, String], default: null },
  },
  setup() {
    const authorization = useAuthorization();

    return {
      ...authorization,
    };
  },
  methods: {
    triggerAuthModal() {
      this.$vfm.hide('AppModalDrawer');
      setTimeout(() => {
        this.openAuthorizationModal('Get Discounted Price');
      }, 300);
    },
  },
});
</script>

<style lang="less">
.auth-price-desc {
  .small;

  padding: 0;
}
</style>
